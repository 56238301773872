import { React, useState } from "react";
import { Col, Row } from "react-bootstrap";
import FormfacadeEmbed from "@formfacade/embed-react";
import './nala.css';
import Nala1 from './Nala1.jpg';
import Nala2 from './Nala2.jpg';
import Nala3 from'./Nala3.jpg';
import Nala4 from './Nala4.jpg';
import Nala5 from './Nala5.jpg';
import Nala6 from './Nala6.jpg';
import Nala7 from './Nala7.jpg';
import ImageModal from "./ImageModal";

<FormfacadeEmbed

formFacadeURL="https://formfacade.com/include/116843666599781547797/form/1FAIpQLSeUjGqpAXDVs2P3IcfPkVUDEZGZH90td0IsmyT7snTfuv2_FA/classic.js/?div=ff-compose"

onSubmitForm={() => console.log('Form submitted')}

/>

const Nala = () => {
   
    return (
      <div className="nala-back">
        <div className="container">
            <Row className="nala">
                <Col className="nala-content">
                    <Row>
                        <h2 className="nala-header permanent-marker"> Nala is Missing! </h2>
                        <hr className="nala-hr"/>
                    </Row>
                    <Row className="nala-rows">
                        <Col lg="6">
                            <img 
                                src={Nala3}
                                id="Nala1" 
                                alt="nala-3" 
                                className="nala-img1 nala-img" 
                                onClick={(e) => ImageModal(e)}
                            />
                        </Col>
                        <Col>
                            <p className="nala-text">
                                Nala is a sweet, loving, and gentle lady. She responds to her name, as well as food and treats. She likes long walks 
                                through forests and loves to hide in tall grasses, and especially loves to hang outside alongside her mom, and is fairly vocal 
                                about her very many strong and *important* opinions. She's inquisitive, loves to be outside, and 
                                is a very good mouser. Her favorite place for naps is on top of you when you are laying down, and any window with some sun and a soft cushion.
                                Nala is scared of loud noises, especially engines and vehicle noises. She loves canned wet food and, of course, all the treats.
                            </p>
                            <p className="nala-text">
                                Nala is around 7 years old, and has medium-long black hair with red undertones, a small white spot on her lower belly, 
                                green eyes, and a freckle on her left eye. She is spayed. She is due for a rabies shot in the fall. She does not have 
                                a chip. She weighed about 10-10.5 lbs when she went missing. She was healthy, and knows her way about the forests and how
                                to protect herself. She has not been declawed.
                            </p>
                        </Col>
                    </Row>
                    <Row className="nala-rows">
                        <Col lg="6">
                            <img src={Nala6} alt="nala-1" className="nala-img2 nala-img" />
                        </Col>
                        <Col>
                            <p className="nala-text">
                                Nala is very loved. She has been with me since 2018, and has a daughter named Pepper that also misses her. I am absolutely 
                                heartbroken and distraught over not knowing what happened. I want to believe that she is alive and safe in someone's house, 
                                who did not know she had a loving mom worrying about her safety. If this is the case, especially with summer coming, 
                                her need to escape will increase (she is a very sneaky girl), and if she is not somewhere she recognizes, she may have difficulty 
                                finding her way home, as we've only been in the area for 2 years. 
                            </p>
                        </Col>
                    </Row>
                    <Row className="nala-images">
                        <Col>
                            <Row className="nala-img-rows">
                                <Col lg="4">
                                    <img src={Nala2} className="nala-img3 nala-img" alt="Nala2" />
                                </Col>
                                <Col lg="4">
                                    <img src={Nala1} className="nala-img3 nala-img" alt="Nala1" />
                                </Col>
                                <Col lg="4">
                                    <img src={Nala4} className="nala-img3 nala-img" alt="Nala3" />
                                </Col>
                            </Row>
                            <Row className="nala-img-rows">
                                <Col lg="5">
                                    <img src={Nala5} className="nala-img3 nala-img" alt="Nala5" />
                                </Col>
                                <Col lg="7">
                                    <img src={Nala7} className="nala-img3 nala-img" alt="Nala7" />
                                </Col>
                            </Row>
                        </Col>
                    </Row>
                    <Row className="nala-rows">
                        <Col md="2">
                        </Col>
                        <Col md="8" className="nala-contact">
                            <p>
                                If you have any information or questions, please don't hesitate to call/text/message the following
                                contact information, and I'll return your call as soon as possible. Thank you so much!
                                
                            </p>
                            <p>
                                Phone (text or call): 715-660-6963
                            </p>
                        </Col>
                        <Col md="2">
                        </Col>
                    </Row>
                    <Row>
                        <FormfacadeEmbed
                            formFacadeURL="https://formfacade.com/include/116843666599781547797/form/1FAIpQLSeUjGqpAXDVs2P3IcfPkVUDEZGZH90td0IsmyT7snTfuv2_FA/classic.js/?div=ff-compose"
                            onSubmitForm={() => console.log('Form submitted')}
                        />
                    </Row>
                </Col>
            </Row>
        </div>
      </div>
    );
  };
  
  export default Nala;