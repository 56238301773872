import React from "react";
import ModalImage from "react-modal-image";


const ImageModal = (e) => {
    const img = e.target.src
    return (
        <div>
            <ModalImage large={img}/>
        </div>
    )
}

export default ImageModal;